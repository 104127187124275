import React, { useMemo } from "react";

import Box from "@material-ui/core/Box";

import { customStyles } from "./styles";
import { Grid } from "@material-ui/core";
import { useAuth } from "@api/auth";
import CompanyPicker from "../components/CompanyPicker";

import Logo from "~/assets/vectors/creditapp.svg";

const ConnectDisplay: React.FC = () => {
	const { user, selectedCompany } = useAuth();
	const styles = customStyles();
	const content = useMemo(() => {

		return (
			<Grid container spacing={4} style={{ maxWidth: 400, textAlign: 'center', margin: '0 auto' }}>
				<Grid item xs={12}>
					<Logo />
				</Grid>
				<Grid item xs={12}>
					<h2 style={{ margin: 0 }}>Choose the company to connect with CreditApp</h2>
				</Grid>
				<Grid item xs={12}>
					<CompanyPicker />
				</Grid>
			</Grid>
		);
	}, [selectedCompany, user?.autocorpAdmin]);

	return <Box className={styles.content}>{content}</Box>;
};

export default ConnectDisplay;
