import React, { useEffect, useMemo } from "react";

import { useConnectContext } from "../../context";

import { InfoCard } from "~/components/InfoCard";
import { Typography } from "~/components/Typography";

import Box from "@material-ui/core/Box";

import { useSingleCardStyles } from "../../common/styles";
import { Loading } from "~/components/Loading";
import { Grid } from "@material-ui/core";
import { useLocation } from "@hooks/use-location";
import { parseQuery } from "@utils/url";

const CompanyPicker = () => {
	// const styles = useSingleCardStyles();
	// const { state: { loading, user, data }, actions: { connectCompany } } = useConnectContext();
	// const location = useLocation();
	// const query = parseQuery(location.search);


	// useEffect(() => {
	// 	if (data?.setCreditAppRetailerId.success) {
	// 		setTimeout(() => window.close(), 2000);
	// 	}
	// }, [data]);

	// const content = useMemo(() => {
	// 	if (loading) {
	// 		return <Loading />;
	// 	}

	// 	if (data?.setCreditAppRetailerId.success) {
	// 		return (
	// 			<>
	// 				<Grid item xs={12}>
	// 					<Box className={styles.content}>

	// 					</Box>
	// 				</Grid>
	// 				<Grid item xs={12}>
	// 					<Box className={styles.content}>
	// 						<Typography>Successfully connected company with CreditApp.</Typography>
	// 						<Typography>You may close this page.</Typography>
	// 					</Box>
	// 				</Grid></>
	// 		);
	// 	}

	// 	if (!query.retailerId) {
	// 		return (
	// 			<>
	// 				<Grid item xs={12}>
	// 					<Box className={styles.content}>
	// 					</Box>
	// 				</Grid>
	// 				<Grid item xs={12}>
	// 					<Box className={styles.content}>
	// 						<Typography>CreditApp's Retailer Id is missing.</Typography>
	// 						<Typography>Please close this window, and try again.</Typography>
	// 					</Box>
	// 				</Grid></>
	// 		);
	// 	}

	// 	if (!user?.companies.length) {
	// 		return (
	// 			<>
	// 				<Grid item xs={12}>
	// 					<Box className={styles.content}>
	// 					</Box>
	// 				</Grid>
	// 				<Grid item xs={12}>
	// 					<Box className={styles.content}>
	// 						<Typography>No companies connected to this user.</Typography>
	// 					</Box>
	// 				</Grid></>
	// 		);
	// 	}

	// 	return user?.companies.map((company) => {
	// 		return (
	// 			<Grid item xs={12} onClick={() => connectCompany(company?.id!, query.retailerId as string)}>
	// 				<InfoCard loading={loading} className={styles.button}>
	// 					<Box className={styles.content}>
	// 						<Typography>{company?.name}</Typography>
	// 						{/* <Typography>{company?.companyProducts.nodes.map(cp => cpname(cp.product?.name)).join(", ")}</Typography> */}
	// 					</Box>
	// 				</InfoCard>
	// 			</Grid>
	// 		);
	// 	})

	// }, [loading, user, data, query, connectCompany]);

	const content = <></>
	return (
		<Grid container spacing={2}>
			{content}
		</Grid>
	);
};

export default CompanyPicker;
