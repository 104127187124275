import { ALSUser, useAuth } from "@api/auth";
import { useConnectCompany } from "@api/companies";
// import { ConnectCompanyMutation } from "@api/graphql/types";
import { FetchResult } from "@apollo/client";
import React, { createContext, useContext, useMemo } from "react";

interface ConnectCompanyMutation { }

interface Data {
	state: {
		// error?: any;
		loading: boolean;
		// user?: ALSUser;
		// data?: ConnectCompanyMutation | null | undefined;
	},
	actions: {
		// connectCompany: (companyId: number, retailerId: string) => Promise<FetchResult<ConnectCompanyMutation>>;
	}
}

const defaultContext: Data = {
	state: {
		// error: "" as any,
		loading: false,
		// data: {} as ConnectCompanyMutation,
	},
	actions: {
		// connectCompany: (companyId: number, retailerId: string) => Promise.resolve({} as FetchResult<ConnectCompanyMutation>),
	}
};

const ConnectContext = createContext(defaultContext);

export const useConnectContext = () => {
	const context = useContext(ConnectContext);
	if (!context) {
		throw new Error(
			"You cannot use ConnectContext from outside of its Provider",
		);
	}

	return context;
};

const useConnectContextValue = () => {
	const { loading, user } = useAuth();
	const { state, actions } = useConnectCompany();

	const data = useMemo(() => {
		return {
			state: {
				...state,
				loading: loading || state.loading,
				user,
			},
			actions,
		};
	}, [state, loading, user, actions]);

	return data;
};

export const ConnectProvider: React.FC = ({ children }) => {
	const contextValue = useConnectContextValue();

	return (
		<ConnectContext.Provider value={contextValue}>
			{children}
		</ConnectContext.Provider>
	);
};
