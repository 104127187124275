import React from "react";
import { ConnectProvider } from "./_lib/context";
import ConnectDisplay from "./_lib/Display";

import { ViewComponent, createConnectView } from "./_lib/view";

const Connect: ViewComponent = (props) => {
	return (
		<ConnectProvider>
			<ConnectDisplay {...props} />
		</ConnectProvider>
	);
};

export default createConnectView(Connect, {
	title: "Connect",
});
