import { makeStyles } from "@material-ui/core/styles";

export const customStyles = makeStyles(() => ({
	content: {
		padding: 24,
		display: "flex",
		flexDirection: "column",
		flexWrap: "nowrap",
		height: "100%",
		"& .MuiTableCell-stickyHeader": {
			border: "none",
			borderBottom: "1px solid #CACACA",
		},
	},
}));
