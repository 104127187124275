import { createPrivateView, ViewComponent } from "~/model/view/private";

export const createConnectView: typeof createPrivateView = (
	component,
	props = {},
) => {
	return createPrivateView(component, {
		...props,
		showBack: false,
	});
};

export type { ViewComponent };
